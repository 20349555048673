import BaseController from "controllers/base_controller"

export default class extends BaseController {
  initialize () {
    this.clearEmployee = this.clearEmployee.bind(this)
  }

  connect () { }
  disconnect () { }

  clearEmployee (e) {
    let base = document.location.href.split('?')[0]

    if (base) {
      let query = this.updateParams(rsc.params, {}, ['employee_id', 'message_page'])

      this.visit(`${base}?${query}`)
    }
  }
}
