import BaseController from "controllers/base_controller"

export default class extends BaseController {
  static targets = ['input', 'icon']

  initialize () {
    this.querySubmitted = this.querySubmitted.bind(this)
    this.searchChanged = this.searchChanged.bind(this)

    this.saveFocus = this.saveFocus.bind(this)
  }

  connect () {
    if (!this.hasInputTarget) return
    if (!this.hasIconTarget) return

    this.searchForm = this.inputTarget.parentElement

    this.searchForm.addEventListener('submit', this.querySubmitted)

    this.inputTarget.addEventListener('search', this.searchChanged)
    this.inputTarget.addEventListener('focus', this.saveFocus)

    this.stopSpinning()
    this.checkFocus()
  }

  disconnect () {
    if (!this.hasInputTarget) return

    this.searchForm.removeEventListener('submit', this.querySubmitted)

    this.inputTarget.removeEventListener('search', this.searchChanged)
    this.inputTarget.removeEventListener('focus', this.saveFocus)
  }

  startSpinning () {
    this.iconTarget.classList.remove('fa-search')
    this.iconTarget.classList.add('fa-sync-alt', 'fa-spin')
  }

  stopSpinning () {
    this.iconTarget.classList.add('fa-search')
    this.iconTarget.classList.remove('fa-sync-alt', 'fa-spin')
  }

  saveFocus () {
    if (rsc) {
      rsc.focus = this.inputTarget.id
    }
  }

  loseFocus () {
    if (rsc) {
      rsc.focus = undefined
    }
  }

  checkFocus () {
    if (rsc && rsc.focus && rsc.focus === this.inputTarget.id) {
      this.restoreFocus()
    }
  }

  restoreFocus () {
    let length = this.inputTarget.value.length;

    this.inputTarget.focus()

    if (this.inputTarget.setSelectionRange) {
      this.inputTarget.setSelectionRange(length, length)
    } else if (this.inputTarget.createTextRange) {
      let range = this.inputTarget.createTextRange()

      range.collapse(true)
      range.moveEnd('character', length)
      range.moveStart('character', length)
      range.select()
    }
  }

  querySubmitted (e) {
    this.stopEvent(e)

    let url = this.formUrl(this.searchForm)

    this.startSpinning()
    this.visit(url)
  }

  searchChanged (e) {
    if (e.target.value == "") {
      let url = this.formUrl(this.searchForm)

      this.startSpinning()
      this.loseFocus()
      this.visit(url)
    }
  }
}
