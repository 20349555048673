import axios from "axios"

export default {
  _setCsrfHeader: function() {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = $('meta[name="csrf-token"]').attr('content')
  },

  _callEndpoint: function(type, url, data, success, error) {
    this._setCsrfHeader()

    axios({
      method:       type,
      dataType:     'json',
      contentType:  'application/json',
      url:          url,
      data:         data,
    }).then(function(response) {
      rsc.log(`API: AJAX SUCCESS: ${type}: ${url}`)

      if (success) {
        success(response)
      }
    }).catch(function(err) {
      rsc.log(`API: AJAX ERROR: ${type}: ${url}: [${error}]`)

      if (error) {
        error(err)
      }
    })
  },

  post: function(url, data, success, error) {
    this._callEndpoint('post', url, data, success, error)
  },

  patch: function(url, data, success, error) {
    this._callEndpoint('patch', url, data, success, error)
  },
}
