import BaseController from "controllers/base_controller"

export default class extends BaseController {
  initialize () {
    this.sendText = this.sendText.bind(this)
  }

  connect () { }
  disconnect () { }

  sendText (e) {
    this.stopEvent(e)

    let modal = this.getControllerFor('#modal', 'modal');

    var params = {
      id:     this.element.dataset.id,
      name:   this.element.dataset.name,
      number: this.element.dataset.number,
    }

    modal.open('employee', params)
  }
}
