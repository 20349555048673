import Pusher from "pusher-js"

import audioAlert from "page/audio"

export default class Realtime {
  constructor () {
    rsc.log('REALTIME: initializing');

    this.initializeData()
    this.initializeElements()
    this.initializeCallbacks()

    rsc.log('REALTIME: initialized');
  }

  initializeData () {
    this.colors = {
      initial:      'text-gray-400',
      connected:    'text-green-400',
      connecting:   'text-yellow-400',
      disconnected: 'text-red-400',
    }
  }

  initializeElements () {
    this.element = document.querySelector('#realtime')
    this.indicator = this.element.querySelector('i')
  }

  initializeCallbacks () {
    let callbacks = [
      'socketConnected',
      'socketConnecting',
      'socketDisconnected',

      'channelMessageArrived',
      'channelMessageSent',
      'channelMessageStatusChanged',
      'channelMessageUpdated',

      'sendEvent',
    ]

    for (const callback in callbacks) {
      this[callbacks[callback]] = this[callbacks[callback]].bind(this)
    }
  }

  connect () {
    rsc.log('REALTIME: connecting');

    this.initializePusherChannel()
    this.addConnectionEventListeners()
    this.addChannelEventListeners()

    rsc.log('REALTIME: connected');
  }

  initializePusherChannel () {
    this.pusher = new Pusher(rsc.pusher.key, rsc.pusher.options)
    this.channel = this.pusher.subscribe(rsc.pusher.channel)
  }

  addConnectionEventListeners () {
    this.pusher.connection.bind('state_change', function(states) {
      if (states.current == 'connected') {
        this.socketConnected()
      } else if (states.current == 'connecting') {
        this.socketConnecting()
      } else {
        this.socketDisconnected()
      }
    }, this);
  }

  addChannelEventListeners () {
    this.channel.bind('new', function(text_message) {
      this.channelMessageArrived(text_message)
    }, this)

    this.channel.bind('sent', function(text_message) {
      this.channelMessageSent(text_message)
    }, this)

    this.channel.bind('status', function(text_message) {
      this.channelMessageStatusChanged(text_message)
    }, this)

    this.channel.bind('mark', function(text_message) {
      this.channelMessageUpdated(text_message)
    }, this);
  }

  disconnect () {
    rsc.log('REALTIME: disconnecting');

    this.pusher.disconnect()

    this.pusher.connection.unbind('state_change')

    this.channel.unbind('new')
    this.channel.unbind('sent')
    this.channel.unbind('status')
    this.channel.unbind('mark')

    rsc.log('REALTIME: disconnected');
  }

  resetIndicator () {
    for (const state in this.colors) {
      this.indicator.classList.remove(this.colors[state])
    }
  }

  setIndicator (state) {
    this.resetIndicator();

    this.indicator.classList.add(this.colors[state])
  }

  socketConnected () {
    rsc.log('REALTIME: socketConnected');

    this.setIndicator('connected');
  }

  socketConnecting () {
    rsc.log('REALTIME: socketConnecting');

    this.setIndicator('connecting');
  }

  socketDisconnected () {
    rsc.log('REALTIME: socketDisconnected');

    this.setIndicator('disconnected');
  }

  refresh () {
    if (rsc.modal === undefined || rsc.modal === 'closed') {
      Turbolinks.visit(document.location.href)
    }
  }

  isTenantMessage (text_message) {
    let tenant = rsc.params.tenant

    if (tenant === null) {
      return true
    }

    if (parseInt(tenant) == text_message.tenant_id) {
      return true
    }

    return false
  }

  channelMessageArrived (text_message) {
    rsc.log('REALTIME: channelMessageArrived');

    rsc.dir(text_message)

    if (this.isTenantMessage(text_message)) {
      audioAlert('new')

      setTimeout(this.refresh, 500)
    } else {
      rsc.log(
        `REALTIME: channelMessageArrived: skipping refresh: ` +
        `tennant: ${rsc.params.tenant}, ` +
        `message-tenant: ${text_message.tenant_id}`
      )
    }
  }

  channelMessageSent (text_message) {
    rsc.log('REALTIME: channelMessageSent');

    if (this.isTenantMessage(text_message)) {
      this.refresh()
    } else {
      rsc.log(
        `REALTIME: channelMessageSent: skipping refresh: ` +
        `tennant: ${rsc.params.tenant}, ` +
        `message-tenant: ${text_message.tenant_id}`
      )
    }
  }

  channelMessageStatusChanged (text_message) {
    rsc.log('REALTIME: channelMessageStatusChanged');

    if (this.isTenantMessage(text_message)) {
      this.refresh()
    } else {
      rsc.log(
        `REALTIME: channelMessageStatusChanged: skipping refresh: ` +
        `tennant: ${rsc.params.tenant}, ` +
        `message-tenant: ${text_message.tenant_id}`
      )
    }
  }

  channelMessageUpdated (text_message) {
    rsc.log('REALTIME: channelMessageUpdated');

    if (this.isTenantMessage(text_message)) {
      this.refresh()
    } else {
      rsc.log(
        `REALTIME: channelMessageUpdated: skipping refresh: ` +
        `tennant: ${rsc.params.tenant}, ` +
        `message-tenant: ${text_message.tenant_id}`
      )
    }
  }

  sendEvent (data) {
    this.channel.trigger('client-send', data);
  }
}
