import BaseController from "controllers/base_controller"

export default class extends BaseController {
  static targets = ['tenantSelector']

  initialize () {
    this.textEmployees = this.textEmployees.bind(this)
    this.textNumbers = this.textNumbers.bind(this)

    this.muteAudio = this.muteAudio.bind(this)
    this.unmuteAudio = this.unmuteAudio.bind(this)

    this.tenantChanged = this.tenantChanged.bind(this)
  }

  connect () {
    if (!this.hasTenantSelectorTarget) return

    this.tenantSelectorTarget.addEventListener('change', this.tenantChanged)
    this.tenantSelectorForm = this.tenantSelectorTarget.parentElement
  }

  disconnect () {
    if (!this.hasTenantSelectorTarget) return

    this.tenantSelectorTarget.removeEventListener('change', this.tenantChanged)
    this.tenantSelectorForm = undefined
  }

  textEmployees (e) {
    let modal = this.getControllerFor('#modal', 'modal')

    modal.open('employees', {})
  }

  textNumbers (e) {
    let modal = this.getControllerFor('#modal', 'modal')

    modal.open('numbers', {})
  }

  muteAudio (e) {
    document.cookie = 'audio=off'

    this.visit(document.location.href)
  }

  unmuteAudio (e) {
    document.cookie = 'audio=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'

    this.visit(document.location.href)
  }

  tenantChanged (e) {
    let url = this.formUrl(this.tenantSelectorForm)

    this.visit(url)
  }
}
