// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "@fortawesome/fontawesome-free/css/all"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "stylesheets/application"

import "lib/livestamp"
import "lib/select2"

import "page/events"
import "page/audio"
import "page/scrollbars"

import { Application } from 'stimulus'

import HeaderController from 'controllers/header_controller.js'
import ModalController from 'controllers/modal_controller.js'
import SearchController from 'controllers/search_controller.js'

import EmployeesController from 'controllers/employees_controller.js'
import EmployeeController from 'controllers/employee_controller.js'

import MessagesController from 'controllers/messages_controller.js'
import MessageController from 'controllers/message_controller.js'

const application = Application.start()

application.register('header', HeaderController)
application.register('modal', ModalController)
application.register('search', SearchController)

application.register('employees', EmployeesController)
application.register('employee', EmployeeController)

application.register('messages', MessagesController)
application.register('message', MessageController)
