export function isAudioMuted () {
  let audioMuted = false

  let cookie = document.cookie

  if (cookie == "") {
    return audioMuted
  }

  let cookies = cookie.split('; ')

  if (cookies.length > 0 && cookies[0] != '') {
    let cookie = cookies.find(
      row => row.startsWith('audio=')
    )

    if (cookie) {
      audioMuted = (cookie.split('=')[1] == 'off');
    }
  }

  return audioMuted
}

export function audioSource(name) {
  return '<audio id="audio-player" preload="auto">' +
    `<source src="/assets/${name}.mp3" type="audio/mpeg" />` +
    `<source src="/assets/${name}.ogg" type="audio/ogg" />` +
    `<embed hidden="true" loop="false" src="/assets/${name}.mp3" />` +
  '</audio>'
}

export default function audioAlert(name) {
  if (isAudioMuted()) {
    return
  }

  let container = document.getElementById('audio')

  container.innerHTML = audioSource(name)

  let audio = container.querySelector('audio')

  audio.addEventListener('ended', () => {
    container.innerHtml = ''
  })

  audio.play()
}
