import BaseController from "controllers/base_controller"

import api from "page/api"

const US_COUNTRY_CODE = '1'
const US_NUMBER_FORMAT = /^[1-9][0-9]{2}[0-9]{7}$/
const US_NUMBER_WITH_COUNTRY_CODE_FORMAT = new RegExp(`^\\+${US_COUNTRY_CODE}${US_NUMBER_FORMAT.source.slice(1, -1)}$`)

const UK_COUNTRY_CODE = '44'
const UK_NUMBER_FORMAT = /^[1-9][0-9]{2,4}[0-9]{6,8}$/
const UK_NUMBER_WITH_COUNTRY_CODE_FORMAT = new RegExp(`^\\+${UK_COUNTRY_CODE}${UK_NUMBER_FORMAT.source.slice(1, -1)}$`)

const SEGMENT_LENGTH = 160
const KEY_ESC = 27
export default class extends BaseController {
  static values = { status: String }

  static targets = [
    'root',
    'backdrop',
    'panel',
    'replyLabel',
    'replyDetail',
    'numbers',
    'numberCount',
    'recipients',
    'body',
    'bodyCount',
    'signature',
    'submit',
    'cancel',
  ]

  initialize () {
    this.submit = this.submit.bind(this)
    this.cancel = this.cancel.bind(this)

    this.keyup = this.keyup.bind(this)

    this.numbersChanged = this.numbersChanged.bind(this)
    this.recipientsChanged = this.recipientsChanged.bind(this)
    this.bodyChanged = this.bodyChanged.bind(this)

    this.fields = [
      'numbers',
      'recipients',
      'replyLabel',
    ]
  }

  connect () {
    if (!this.hasRootTarget) return
    if (!this.hasBackdropTarget) return
    if (!this.hasPanelTarget) return
    if (!this.hasReplyLabelTarget) return
    if (!this.hasReplyDetailTarget) return
    if (!this.hasNumbersTarget) return
    if (!this.hasNumberCountTarget) return
    if (!this.hasRecipientsTarget) return
    if (!this.hasBodyTarget) return
    if (!this.hasBodyCountTarget) return
    if (!this.hasSignatureTarget) return
    if (!this.hasSubmitTarget) return
    if (!this.hasCancelTarget) return

    this.addEventListeners()
    this.setupEmployeeSelect()

    this.refresh()
  }

  disconnect () {
    if (!this.hasBackdropTarget) return
    if (!this.hasPanelTarget) return
    if (!this.hasReplyLabelTarget) return
    if (!this.hasReplyDetailTarget) return
    if (!this.hasNumbersTarget) return
    if (!this.hasRecipientsTarget) return
    if (!this.hasBodyTarget) return

    this.removeEventListeners()
    this.teardownEmployeeSelect()

    this.refresh()
  }

  addEventListeners () {
    this.backdropTarget.addEventListener('click', this.cancel)
    this.panelTarget.addEventListener('keyup', this.keyup)

    this.numbersTarget.addEventListener('keyup', this.numbersChanged)
    this.bodyTarget.addEventListener('keyup', this.bodyChanged)
  }

  removeEventListeners () {
    this.backdropTarget.removeEventListener('click', this.cancel)
    this.panelTarget.removeEventListener('keyup', this.keyup)

    this.numbersTarget.removeEventListener('keyup', this.numbersChanged)
    this.bodyTarget.removeEventListener('keyup', this.bodyChanged)
  }

  setupEmployeeSelect () {
    this.employeeSelect = $(this.recipientsTarget).select2({
      width: '100%',
      multiple: true,
      placeholder: 'Select one or more employees',
      allowClear: true,
      closeOnSelect: true,
      maximumSelectionLength: 15,
      ajax: {
        delay:    300,
        url:      '/employees/autocomplete',
        dataType: 'json',
        data: function (params) {
          return {
            tenant: params.tenant,
            query: params.term,
          };
        },
        processResults: function(data, params) {
          return {
            results: data,
          };
        },
      },
    });

    $(this.employeeSelect).on('change', this.recipientsChanged)
  }

  teardownEmployeeSelect () {
    $(this.employeeSelect).off('change')
    $(this.employeeSelect).select2('destroy')
  }

  statusValueChanged () {
    this.refresh()
  }

  refresh () {
    if (this.statusValue === 'open') {
      this.updateBodyCountText()
      this.updateNumberCountText()
      this.updateSubmitButton()

      this.setSignature()

      this.show()
    } else {
      this.hide()
    }
  }

  updateSubmitButton () {
    let canSubmit = false

    if (this.hasBody() && this.hasRecipients()) {
      canSubmit = true
    }

    if (canSubmit) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  hasBody () {
    return this.bodyTarget.value.length > 0
  }

  hasRecipients () {
    switch(this.mode) {
      case 'employees':
        return (this.getEmployeeIds().length > 0)
      case 'numbers':
        return (this.getNumbers().length > 0)
      case 'employee':
        return (this.params.id !== null)
      case 'number':
        return (this.params.number !== null)
      default:
        rsc.log('MODAL: ERROR: unknown mode in hasRecipients: ' + this.mode);
        break
    }
  }

  enableSubmitButton () {
    if (this.submitTarget.getAttribute('disabled') !== null) {
      this.submitTarget.removeAttribute('disabled')

      this.submitTarget.classList.remove('disabled')
      this.submitTarget.classList.add('enabled')
    }
  }

  disableSubmitButton () {
    if (this.submitTarget.getAttribute('disabled') == null) {
      this.submitTarget.classList.remove('enabled')
      this.submitTarget.classList.add('disabled')

      this.submitTarget.setAttribute('disabled', '')
    }
  }

  show () {
    switch(this.mode) {
      case 'employees':
        this.recipientsTarget.autofocus = true
        this.showField('recipients')
        break

      case 'numbers':
        this.numbersTarget.autofocus = true
        this.showField('numbers')
        break

      case 'employee':
      case 'number':
        this.updateReplyHeader()
        this.showField('replyLabel')
        break

      default:
        rsc.log('ERROR: unknown mode in show: ' + this.mode);
        break
    }

    this.showModal()
  }

  showModal () {
    this.rootTarget.classList.remove('hidden')

    this.backdropTarget.classList.remove('opacity-0')
    this.backdropTarget.classList.add('opacity-75')

    this.panelTarget.classList.remove('opacity-0')
    this.panelTarget.classList.add('opacity-100')
  }

  resetFields () {
    for (const field in this.fields) {
      const key = `${this.fields[field]}Target`

      this[key].parentElement.classList.add('hidden')
    }
  }

  showField (name) {
    const key = `${name}Target`

    this.resetFields()
    this[key].parentElement.classList.remove('hidden')
  }

  updateReplyHeader () {
    this.replyLabelTarget.innerHTML = this.renderReplyLabel()
    this.replyDetailTarget.innerHTML = this.renderReplyDetail()
  }

  setSignature () {
    this.signatureTarget.innerHTML = this.renderSignature()
  }

  renderReplyLabel() {
    return (
      `<span class="text-gray-500">` +
        `${this.params.name}` +
      `</span>`
    )
  }

  renderReplyDetail () {
    return (
      `<span class="italic text-gray-400">` +
      // `${this.formatNumber(this.params.number)}` +
      `${this.params.number}` +
      `</span>`
    )
  }

  renderSignature () {
    return (
      `<span class="text-gray-400">` +
        `${this.userSignature()}` +
      `</span>`
    )
  }

  hide () {
    this.hideModal()
    this.resetFields()
  }

  hideModal () {
    this.rootTarget.classList.add('hidden')

    this.backdropTarget.classList.remove('opacity-75')
    this.backdropTarget.classList.add('opacity-0')

    this.panelTarget.classList.remove('opacity-100')
    this.panelTarget.classList.add('opacity-0')
  }

  reset () {
    this.bodyTarget.value = null
    $(this.employeeSelect).val(null).trigger('change')
  }

  syncState () {
    rsc.modal = this.statusValue
  }

  open (mode, params) {
    this.mode = mode
    this.params = params

    this.reset()

    this.statusValue = 'open'
    this.syncState()
  }

  close () {
    this.statusValue = 'closed'
    this.syncState()
  }

  submit (e) {
    this.sendMessage()
    this.close()
  }

  cancel (e) {
    this.close()
  }

  keyup (e) {
    if (e.keyCode == KEY_ESC) {
      this.cancel(e)
    }
  }

  getRecipients () {
    switch(this.mode) {
      case 'employees':
        return this.getEmployeeIds()
      case 'numbers':
        return this.getNumbers()
      case 'employee':
        return this.params.id
      case 'number':
        return this.params.number

      default:
        rsc.log('MODAL: ERROR: unknown mode in getRecipients: ' + this.mode);
        break
    }
  }

  sendMessage (e) {
    var message = {
      mode:      this.mode,
      to:        this.getRecipients(),
      body:      this.signedBodyText(),
      sender_id: rsc.user.id,
      token:     rsc.user.email,
    }

    api.post(
      '/messages/send_message', message,
      function(message) {
        rsc.log(`MODAL: sendMessage: SUCCESS: ${message}`)
      },
      function(error) {
        rsc.log(`MODAL: sendMessage: ERROR: ${error}`)
      }
    )
  }

  numbersChanged (value) {
    this.refresh()
  }

  recipientsChanged (value) {
    this.refresh()
  }

  getNumbers () {
    return this.parseNumbers()
  }

  getEmployeeIds () {
    return $(this.employeeSelect).select2('data').map(function(one) {
      return one.id
    })
  }

  bodyChanged (value) {
    this.refresh()
  }

  bodyText () {
    return this.bodyTarget.value
  }

  userSignature () {
    return rsc.user.org_tag + ' - ' + rsc.user.first_name
  }

  signedBodyText () {
    return this.bodyText() + ' ' + this.userSignature()
  }

  countBody () {
    var size = this.signedBodyText().length
    var left = SEGMENT_LENGTH

    if (size < SEGMENT_LENGTH) {
      left = SEGMENT_LENGTH - size
    } else {
      var over = SEGMENT_LENGTH - (size % SEGMENT_LENGTH)
      var used = Math.floor(size / SEGMENT_LENGTH)

      left = used + ' / ' + over
    }

    return (
      `${left} <span class='text-xs text-gray-400'>` +
        `<em>(including signature)</em>` +
      `</span>`
    )
  }

  updateBodyCountText () {
    this.bodyCountTarget.innerHTML = this.countBody()
  }

  parseNumbers () {
    var value = this.numbersTarget.value

    var nums = []

    if (value.length > 0) {
      var list = value.split(',')

      nums = list.map(function (entered) {
        var num = "+" + entered.replace(/\D/g, '')

        if (num.match(US_NUMBER_WITH_COUNTRY_CODE_FORMAT) || num.match(UK_NUMBER_WITH_COUNTRY_CODE_FORMAT)) {
          return num
        } else {
          return ''
        }
      })

      nums = nums.filter(num => num);
    }
    return nums
  }

  countNumbers () {
    var nums = this.parseNumbers()

    return (
      `${nums.length} <span class='text-xs text-gray-400'>` +
        `<em>valid numbers</em>` +
      `</span>`
    )
  }

  updateNumberCountText () {
    this.numberCountTarget.innerHTML = this.countNumbers()
  }
}
