// Event listeners/handlers

import Realtime from "page/realtime"

document.addEventListener('DOMContentLoaded', function() {
  if (rsc && rsc.pusher && rsc.realtime === undefined) {
    rsc.realtime = new Realtime()
    rsc.realtime.connect()
  }
});

$(document).on('ajax:before', '[data-remote]', () => {
  Turbolinks.clearCache();
});

$(document).on('turbolinks:load', function() {
  // Things that need to happen on every turbolinks load
});
