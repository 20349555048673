import { Controller } from "stimulus"

export default class extends Controller {
  visit (url) {
    if (rsc.modal === undefined || rsc.modal == 'closed') {
      Turbolinks.visit(url)
    }
  }

  stopEvent (e) {
    e.preventDefault()
    e.stopPropagation()
  }

  getControllerFor (selector, identifier) {
    let element = document.querySelector(selector)

    return this.application.getControllerForElementAndIdentifier(
      element,
      identifier
    )
  }

  updateParams (params, replacements, exclude) {
    let query = []

    for (const key in params) {
      if (replacements[key] !== undefined) {
        query.push(`${key}=${replacements[key]}`)
      } else {
        if (params[key] === null || params[key] === undefined || params[key] === '') {
          continue
        }

        if (exclude.includes(key)) {
          continue
        }

        query.push(`${key}=${params[key]}`)
      }
    }

    return query.join('&')
  }

  // Form related functions that parse the inputs from a given form
  // and returning the result in various ways.
  formInputs (form) {
    let inputs = []
    let data = new FormData(form)

    for (var pair of data.entries()) {
      inputs[pair[0]] = pair[1]
    }

    return inputs
  }

  formQuery (form) {
    let query = []
    let inputs = this.formInputs(form)

    for (var name in inputs) {
      query.push(`${name}=${inputs[name]}`)
    }

    return query.join('&')
  }

  formUrl (form) {
    let base = document.location.href.split('?')[0]

    let query = this.formQuery(form)

    return `${base}?${query}`
  }
}
