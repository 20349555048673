function init_scrollbars () {
  if (rsc.scrollbars == undefined) {
    rsc.scrollbars = {
      elements:  ['#employees-panel', '#messages'],
      positions: {},
    }
  }
}

$(document).on('turbolinks:before-visit', function() {
  var i, len, selector;

  init_scrollbars();
  rsc.scrollbars.positions = {};

  for (i = 0, len = rsc.scrollbars.elements.length; i < len; i++) {
    selector = rsc.scrollbars.elements[i];
    rsc.scrollbars.positions[selector] = $(selector).scrollTop();
  }
});

$(document).on('turbolinks:load', function() {
  var scrollTop, selector;

  init_scrollbars();

  for (selector in rsc.scrollbars.positions) {
    scrollTop = rsc.scrollbars.positions[selector];
    $(selector).scrollTop(scrollTop);
  }
});
