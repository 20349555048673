import BaseController from "controllers/base_controller"

import api from "page/api"

export default class extends BaseController {
  static targets = ['timestamp']

  initialize () {
    this.sendText = this.sendText.bind(this)
    this.toggleRead = this.toggleRead.bind(this)
    this.textHistory = this.textHistory.bind(this)
  }

  connect () {
    this.setTimestamp()
  }

  disconnect () { }

  isToday(timestamp) {
    let now = new Date()

    if (timestamp.getUTCFullYear() == now.getUTCFullYear()) {
      if (timestamp.getUTCMonth() == now.getUTCMonth()) {
        if (timestamp.getUTCDate() == now.getUTCDate()) {
          return true
        }
      }
    }

    return false
  }

  setTimestamp() {
    let timestamp = new Date(this.timestampTarget.getAttribute('datetime'))

    let time = timestamp.toLocaleTimeString('en-us', { timeStyle: 'short' })

    if (this.isToday(timestamp)) {
      this.timestampTarget.innerText = `${time}`
    } else {
      let date = timestamp.toLocaleDateString('en-us', { dateStyle: 'short' })

      this.timestampTarget.innerText = `${date} at ${time}`
    }
  }

  sendText (e) {
    let modal = this.getControllerFor('#modal', 'modal')

    var params = {
      id:     this.element.dataset.employeeId,
      name:   this.element.dataset.name,
      number: this.element.dataset.number,
    }

    if (this.element.dataset.employeeId) {
      modal.open('employee', params)
    } else {
      modal.open('number', params)
    }
  }

  markRead (messageId) {
    this.mark(messageId, 'read', true)
  }

  markUnread (messageId) {
    this.mark(messageId, 'read', false)
  }

  mark (messageId, flagName, flagValue) {
    let params = {flag: flagName, value: flagValue}

    api.patch(
      `/messages/${messageId}/mark`, params,
      function(message) {
        rsc.log(`MESSAGE: MARK: SUCCESS: ${message}`)
      },
      function(error) {
        rsc.log(`MESSAGE: MARK: ERROR: ${error}`)
      }
    )
  }

  toggleRead (e) {
    var id = this.element.dataset.id
    var read = e.currentTarget.dataset.read

    if (read == 'true') {
      this.markUnread(id)
    } else {
      this.markRead(id)
    }
  }

  textHistory (e) {
    var id = this.element.dataset.employeeId

    let base = document.location.href.split('?')[0]

    if (id) {
      let query = this.updateParams(rsc.params, { employee_id: id }, ['message_page'])

      this.visit(`${base}?${query}`)
    } else {
      var number = this.element.dataset.number

      if (number) {
        let query = this.updateParams(rsc.params, { number: number }, [])

        this.visit(`${base}?${query}`)
      }
    }
  }
}
